import { IconDefinition } from './icon.js';

let registry: Map<string, string> = new Map<string, string>();

export function getRegistry() {
  if (!registry) {
    registry = new Map<string, string>();
    return registry;
  }
  return registry;
}

export function registerIcon(iconDefinition: IconDefinition) {
  if (!registry.has(iconDefinition.iconName)) {
    registry.set(iconDefinition.iconName, iconDefinition.svgContents);
  }
}
