// class definition
import 'element-internals-polyfill';
import { property, query } from '@horizon/base/decorators.js';
import { LitElement, TemplateResult, nothing } from '@horizon/base';
import { ifDefined, live, classMap } from '@horizon/base/directives.js';
import { eventEmitter } from '@horizon/common/events';
import { html } from '@horizon/base/static-html.js';
import { HznIconButtonTone, HznIconButtonType } from '../types.js';
import { submit } from '@open-wc/form-helpers';
import IconButtonStyles from './icon-button.css.js';

/**
 *
 * @tag hzn-icon-button
 * @tagname hzn-icon-button
 * @summary A component for making an icon-only button
 *
 * @fires {HznIconButtonClickEvent} click - Emitted when the button is clicked
 */

export class HznIconButton extends LitElement {
  /**
   * @private
   */
  #emit = eventEmitter(this);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #internals!: any;

  static formAssociated = true;

  static styles = [IconButtonStyles];

  /**
   * The internal native button
   */
  @query('.icon-button') innerButton!: HTMLButtonElement | HTMLAnchorElement;

  /**
   * Reduces height of button
   */
  @property({ type: Boolean }) compact?: boolean = false;

  /**
   * Renders the button as filled
   */
  @property({ type: Boolean }) filled?: boolean = false;

  /**
   * Renders the button as outlined
   */
  @property({ type: Boolean }) outlined?: boolean = false;

  /**
   * Sets the tone for the button
   * @playroomValues {'neutral' | 'interactive' | 'inverse' | 'critical'}
   */
  @property({ type: String }) tone: HznIconButtonTone = 'neutral';

  /**
   * Disables the button
   */
  @property({ type: Boolean }) disabled?: boolean = false;

  /**
   * Passes a download value to the button
   */
  @property({ type: String }) download?: string;

  /**
   * Sets the name value used for the click event from button
   */
  @property({ type: String }) name?: string;

  /**
   * Sets the icon button to square shape
   */
  @property({ type: Boolean }) square?: boolean;

  /**
   * Passes a type value to the button tag
   * @playroomValues {'button' | 'submit' | 'reset'}
   */
  @property({ type: String }) type?: HznIconButtonType;

  /**
   * Passes a value property to the button tag
   */
  @property({ type: String }) value?: string;

  /**
   * Passes an aria-label value to the button tag
   */
  @property({ type: String }) label = 'icon button';

  connectedCallback() {
    super.connectedCallback();

    if(!this.#internals) {
      this.#internals = this.attachInternals();
    }
  }

  /**
   * Simulates a click on the button
   */
  click() {
    if (this.innerButton) {
      this.innerButton.click();
    }
  }

  /**
   * @private
   */
  #handleClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    const emitClick = () =>
      this.#emit({
        type: 'click',
        eventOptions: {
          detail: {
            name: this.name || undefined,
          },
        },
      });

    if (!this.disabled) {

      // if download, find and click the generated anchor tag
      if (this.download) {
        this.shadowRoot!.querySelector<HTMLAnchorElement>('a[download]')!.click();
        return;
      }

      // if there is no parent form element, just emit the click event and return
      if (!this.#internals.form) {
        // emit action event
        emitClick();
        return;
      }

      // there is a parent form, so check for the type
      if (this.type === 'submit' || !this.type) {
        // submit the parent form and emit click event
        emitClick();
        submit(this.#internals.form);
      } else if (this.type === 'reset') {
        // clear the parent form and emit click event
        emitClick();
        this.#internals.form.reset();
      } else if (this.type === 'button') {
        emitClick();
      }
    }
  }

  // NOTE: if download, generate an anchor tag (CSS hidden) with the download and href attributes
  protected render(): TemplateResult {
    const isInputButton = this.hasAttribute('input-button');

    return html`<button
      ?disabled=${live(this.disabled)}
      @click=${this.#handleClick}
      aria-label=${ifDefined(this.label)}
      name=${ifDefined(this.name)}
      type=${ifDefined(this.type)}
      class=${classMap({
        'icon-button': true,
        'is-disabled': Boolean(this.disabled),
        'is-outlined': !isInputButton && Boolean(this.outlined),
        'is-filled': !isInputButton && Boolean(this.filled),
        'is-square': !isInputButton && Boolean(this.square),
        'is-compact': !isInputButton && Boolean(this.compact),
        'is-tone-neutral': !isInputButton && this.tone === 'neutral',
        'is-tone-interactive': this.tone === 'interactive',
        'is-tone-inverse': !isInputButton && this.tone === 'inverse',
        'is-tone-critical': !isInputButton && this.tone === 'critical',
        'is-input-button': isInputButton
      })}
      value="${ifDefined(this.value)}"
    >
      <slot></slot>
    </button>
    ${this.download ? html`<a download href=${this.download} hidden></a>` : nothing}
  `;
  }
}
