import './SaveCarButton.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import HeartIcon from '../CarTile/svg/HeartIcon';

const SaveCarButton = ({
    stockNumber,
    isFavorite,
    onFavorite,
    onUnfavorite,
    makeModelInfo,
}) => {
    const [savingUnsaving, setSavingUnsaving] = useState(false);
    const [heartHovered, setHeartHovered] = useState(false);

    const onFavoriteClick = () => {
        setSavingUnsaving(true);
        setHeartHovered(false);
        if (isFavorite) {
            onUnfavorite({ stockNumber: stockNumber });
        } else {
            onFavorite(stockNumber);
            setTimeout(() => {
                // Timing out the spinner at 5 seconds in case mykmx refuses to favorite the vehicle.
                setSavingUnsaving(false);
            }, 5000);
        }
    };

    useEffect(() => {
        if (savingUnsaving) {
            setSavingUnsaving(false);
        }
    }, [isFavorite]);

    const heartClass = useMemo(() => {
        if (isFavorite) {
            return 'heart-filled';
        }

        if (heartHovered) {
            return 'heart-hovered';
        }

        return '';
    }, [heartHovered, isFavorite]);

    return (
        <button
            className={classnames('scct--save-car-button', {
                'scct--save-car-button--saved': isFavorite,
            })}
            onClick={onFavoriteClick}
            aria-label={
                isFavorite
                    ? `Un-save this ${makeModelInfo}`
                    : `Save this ${makeModelInfo}`
            }
            data-clickprops={`Element type: Car Tile Save Car,StockNumber: ${stockNumber},Is selected: ${isFavorite}`}
            data-testid={'favorite-button'}
        >
            {savingUnsaving ? (
                <scct-hzn-loading size="small" />
            ) : (
                <div
                    className={heartClass}
                    onMouseOver={() => setHeartHovered(true)}
                    onMouseOut={() => setHeartHovered(false)}
                >
                    <HeartIcon />
                </div>
            )}
        </button>
    );
};

SaveCarButton.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    onFavorite: PropTypes.func.isRequired,
    onUnfavorite: PropTypes.func.isRequired,
    makeModelInfo: PropTypes.string.isRequired,
};

export default SaveCarButton;
