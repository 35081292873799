// class definition
import { TemplateResult, html } from '@horizon/base';
import { property, state } from '@horizon/base/decorators.js';
import { classMap, unsafeSVG } from '@horizon/base/directives';
import { HznIconBase } from './icon-base.js';
import { getRegistry } from './icon-registry.js';

export type IconDefinition = {
  iconName: string;
  svgContents: string;
};

/**
 *
 * @tag hzn-icon
 * @tagname hzn-icon
 * @summary Display any registered system icon
 */
export class HznIcon extends HznIconBase {
  @state() registry!: Map<string, string>;

  constructor() {
    super();
    this.setRegistry(getRegistry());
  }

  /**
   * @private
   */
  setRegistry(registry: Map<string, string>) {
    this.registry = registry;
  }

  /**
   * The name of a registered icon to display
   */
  @property({ type: String }) name = '';

  render(): TemplateResult {
    return html`<div class=${classMap({
      icon: true,
      'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
      'is-variant-filled': this.variant === 'filled',
    })} aria-hidden="true">
      ${unsafeSVG(this.registry.get(this.name))}
    </div>`;
  }
}
